import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FirstScreenComponent } from './pages/first-screen/first-screen.component';

const routes: Routes = [
  { path: "home", component: FirstScreenComponent },
  { path: "", component: FirstScreenComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
