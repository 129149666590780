import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomescreenComponent } from './homescreen/homescreen.component';
import { FirstScreenComponent } from './pages/first-screen/first-screen.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ProductsComponent } from './products/products.component';
import { UsersComponent } from './users/users.component';
import { AppsComponent } from './apps/apps.component';
import { ContactComponent } from './contact/contact.component';
import { MapscreenComponent } from './mapscreen/mapscreen.component';
import { AosdirectiveDirective } from './aosdirective.directive';
import { PolicyComponent } from './policy';
import { TermsComponent } from './terms';
import { RouterModule, Routes } from '@angular/router';

const appRoutes: Routes = [
  { path: '',    component: HomescreenComponent },
  { path: 'policy',    component: PolicyComponent },
  { path: 'terms',      component: TermsComponent },
];
@NgModule({
  declarations: [
    AppComponent,
    HomescreenComponent,
    FirstScreenComponent,
    NavigationComponent,
    ProductsComponent,
    UsersComponent,
    AppsComponent,
    ContactComponent,
    MapscreenComponent,
    AosdirectiveDirective,
    PolicyComponent,
    TermsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    )
  ],
  exports: [
    NavigationComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
