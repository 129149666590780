import { Component, OnInit, HostListener,ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  navLight: boolean = false;
  btnNavLight: boolean = false;
  activeLink: any;
  private toggleButton: any;
  private sidebarVisible: boolean;

  constructor(public location: Location, private element : ElementRef) {
    this.sidebarVisible = false;
    }

  btnActive: "Home" | "About-Us" | "Features" | "Signup" | "Contacts";
  
  ngOnInit() {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
  }
  @HostListener("window:scroll") scroll() {
    let num = window.pageYOffset;
    if (num > 50) {
      this.navLight = true;
    } else if (num < 5) {
      this.navLight = false;
    }
  }

  btnToggle() {
    this.btnNavLight = !this.btnNavLight;
  }

  activatedLink(link) {
    this.activeLink = link;
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    // console.log(html);
    // console.log(toggleButton, 'toggle');

    setTimeout(function(){
        toggleButton.classList.add('toggled');
    }, 500);
    html.classList.add('nav-open');

    this.sidebarVisible = true;
};
sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    // console.log(html);
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
        this.sidebarOpen();
    } else {
        this.sidebarClose();
    }
  };
  
  isHome() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }
      if( titlee === '/home' ) {
          return true;
      }
      else {
          return false;
      }
  }
  headerOff() {
    let toggleBtn = document.getElementById("navToggle");
    if (window.outerWidth <= 992) {
      toggleBtn.click();
    }
  }
  
}
