import { Directive, AfterViewInit, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
  selector: '[b_aos]'
})
export class AosdirectiveDirective {

  @Input() animationIn: string;
  @Input() animationOut: string;

  constructor(private ele: ElementRef, private renderer2: Renderer2) { }


  ngAfterViewInit() {
    this.ele.nativeElement.classList.add('animated');

    var scroll = window.requestAnimationFrame ||

      function (callback) { window.setTimeout(callback, 1000 / 60) };
    var loop = () => {
      if (isElementInViewport(this.ele.nativeElement)) {
        this.ele.nativeElement.classList.remove(this.animationOut || 'visibility_none');
        this.ele.nativeElement.classList.add(this.animationIn);

      } else {
        this.ele.nativeElement.classList.remove(this.animationIn);
        this.ele.nativeElement.classList.add(this.animationOut || 'visibility_none');
      }

      scroll(loop);
    }




    var isElementInViewport = (el) => {
      var rect = el.getBoundingClientRect();
      return (
        (rect.top <= 0
          && rect.bottom >= 0)
        ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight))
        ||
        (rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
      );
    }

    loop();
  }

}
